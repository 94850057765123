import React, { useCallback, useContext, useEffect } from 'react';
import axios from 'axios';
import { Builder } from '@builder.io/react';
import FunnelContext from '../../context/FunnelContext';
import { getParamFromQuery } from '../../utils/getParamFromQuery';
import LoadingCover from '../LoadingCover/LoadingCover';
import useAuth from '../../hooks/useAuth';
import { isTokenExpired } from '../../utils/isTokenExpired';
import { addDataLayer } from '../../utils/dataLayer';
import { replaceUrlState } from '../../utils/replaceUrlState';

const DelaySubscription = props => {
  const { children, subIdsKey = 'sub_ids' } = props;

  const {
    setSubscriptionList,
    setDeliveryDate,
    loadingCover,
    setLoadingCover
  } = useContext(FunnelContext);

  const { token } = useAuth();

  const getSubscriptionList = useCallback(async () => {
    try {
      const {
        data: { data }
      } = await axios.get(
        `${process.env.GATSBY_ENDPOINT_API_SITE}/api/subscription/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (!data.length) {
        replaceUrlState(
          `${window.location.origin + window.location.pathname}?layer=error`
        );
        setLoadingCover(false);
        return;
      }

      const [firstItem = {}] = data;

      addDataLayer('magic_link_delay_subscription_page_view', {
        se_user_id: firstItem?.shippingAddress.id
      });

      const subIds = await getParamFromQuery(subIdsKey).split('-');
      const filteredList = await data.filter(({ id, nextDelivery }) => {
        return (
          subIds.includes(`${id}`) && {
            id,
            nextDelivery
          }
        );
      });
      await setSubscriptionList(filteredList);
      await setDeliveryDate(filteredList[0].nextDelivery);
      await setLoadingCover(false);
    } catch (error) {
      replaceUrlState(
        `${window.location.origin + window.location.pathname}?layer=error`
      );
      setLoadingCover(false);
    }
  }, [token, subIdsKey, setSubscriptionList, setDeliveryDate, setLoadingCover]);

  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.search : '';
    const urlParams = new URLSearchParams(url);
    const isError = urlParams.get('layer') === 'error';

    if (isError) {
      return;
    }

    if (!token || isTokenExpired(token)) return;
    void getSubscriptionList();
  }, [token, getSubscriptionList, setLoadingCover]);

  if (loadingCover && !Builder.isEditing) {
    return <LoadingCover />;
  }

  return <>{children}</>;
};

export default DelaySubscription;
